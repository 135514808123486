<template>
  <div>
    <div
      class="event-board"
      style="text-align: center;"
    >
      <div class="event-checked">
        <!-- TIP 01 완료,실패 한 셋트 -->
        <img
          v-if="historyCountStep1 >= 1"
          class="tips-01"
          src="@/assets/images/events/event240205/completion-1.png"
          alt="TIP01 적립완료"
          style="top:519px; left:89px"
        >
        <img
          v-else-if="checkDateEnd(endDateStep1)"
          class="tips-01"
          src="@/assets/images/events/event240205/fail-1.png"
          alt="TIP01 적립실패"
          style="top:519px; left:89px"
        >

        <!-- TIP 02 완료,실패 한 셋트 -->
        <img
          v-if="historyCountStep1 >= 2"
          class="tips-02"
          src="@/assets/images/events/event240205/completion-2.png"
          alt="TIP02 적립완료"
          style="top:420px; left:243px"
        >
        <img
          v-else-if="checkDateEnd(endDateStep1)"
          class="tips-02"
          src="@/assets/images/events/event240205/fail-2.png"
          alt="TIP02 적립실패"
          style="top:420px; left:243px"
        >

        <!-- TIP 03 완료,실패 한 셋트 -->
        <img
          v-if="historyCountStep1 >= 3"
          class="tips-03"
          src="@/assets/images/events/event240205/completion-3.png"
          alt="TIP03 적립완료"
          style="top:518px; left:397px"
        >
        <img
          v-else-if="checkDateEnd(endDateStep1)"
          class="tips-03"
          src="@/assets/images/events/event240205/fail-3.png"
          alt="TIP03 적립실패"
          style="top:518px; left:397px"
        >

        <!-- TIP 04 완료,실패 한 셋트 -->
        <img
          v-if="historyCountStep2 >= 1"
          class="tips-04"
          src="@/assets/images/events/event240205/completion-4.png"
          alt="TIP04 적립완료"
          style="top:419px; left:550px"
        >
        <img
          v-else-if="checkDateEnd(endDateStep2)"
          class="tips-04"
          src="@/assets/images/events/event240205/fail-4.png"
          alt="TIP04 적립실패"
          style="top:419px; left:550px"
        >

        <!-- TIP 05 완료,실패 한 셋트 -->
        <img
          v-if="historyCountStep2 >= 2"
          class="tips-05"
          src="@/assets/images/events/event240205/completion-5.png"
          alt="TIP05 적립완료"
          style="top:516px; right:96px"
        >
        <img
          v-else-if="checkDateEnd(endDateStep2)"
          class="tips-05"
          src="@/assets/images/events/event240205/fail-5.png"
          alt="TIP05 적립실패"
          style="top:516px; right:96px"
        >

        <!-- TIP 06 완료,실패 한 셋트 -->
        <img
          v-if="historyCountStep2 >= 3"
          class="tips-06"
          src="@/assets/images/events/event240205/completion-6.png"
          alt="TIP06 적립완료"
          style="top:698px; right:97px"
        >
        <img
          v-else-if="checkDateEnd(endDateStep2)"
          class="tips-06"
          src="@/assets/images/events/event240205/fail-6.png"
          alt="TIP06 적립실패"
          style="top:698px; right:97px"
        >

        <!-- TIP 07 완료,실패 한 셋트 -->
        <img
          v-if="historyCountStep3 >= 1"
          class="tips-07"
          src="@/assets/images/events/event240205/completion-7.png"
          alt="TIP07 적립완료"
          style="top:790px; left:549px"
        >
        <img
          v-else-if="checkDateEnd(endDateStep3)"
          class="tips-07"
          src="@/assets/images/events/event240205/fail-7.png"
          alt="TIP07 적립실패"
          style="top:790px; left:549px"
        >

        <!-- TIP 08 완료,실패 한 셋트 -->
        <img
          v-if="historyCountStep3 >= 2"
          class="tips-08"
          src="@/assets/images/events/event240205/completion-8.png"
          alt="TIP08 적립완료"
          style="top:879px; left:392px"
        >
        <img
          v-else-if="checkDateEnd(endDateStep3)"
          class="tips-08"
          src="@/assets/images/events/event240205/fail-8.png"
          alt="TIP08 적립실패"
          style="top:879px; left:392px"
        >

        <!-- TIP 09 완료,실패 한 셋트 -->
        <img
          v-if="historyCountStep3 >= 3"
          class="tips-09"
          src="@/assets/images/events/event240205/completion-9.png"
          alt="TIP09 적립완료"
          style="top:788px; left:235px"
        >
        <img
          v-else-if="checkDateEnd(endDateStep3)"
          class="tips-09"
          src="@/assets/images/events/event240205/fail-9.png"
          alt="TIP09 적립실패"
          style="top:788px; left:235px"
        >

        <!-- TIP 10 완료,실패 한 셋트 -->
        <img
          v-if="historyCountStep3 >= 4"
          class="tips-10"
          src="@/assets/images/events/event240205/completion-10.png"
          alt="TIP10 적립완료"
          style="top:700px; left:73px"
        >
        <img
          v-else-if="checkDateEnd(endDateStep3)"
          class="tips-10"
          src="@/assets/images/events/event240205/fail-10.png"
          alt="TIP10 적립실패"
          style="top:698px; left:73px"
        >
      </div>
      <!-- 배경 -->
      <img
        src="@/assets/images/events/event240205/bg_event_stamp.jpg"
        alt="당뇨병 콩팥병 진료 꿀팁모으기"
        usemap="#tips-map"
      >
      <map name="tips-map">
        <area
          class="checked"
          data-target="tips-01"
          coords="88,605,139,518,239,518,288,606,238,693,138,692,88,605"
          shape="poly"
          title="TIP01"
          @click.stop="createEventHistory(1)"
        >
        <area
          class="checked"
          data-target="tips-02"
          coords="90,519,292,419,392,419,442,506,392,594,292,594,242,506"
          shape="poly"
          title="TIP02"
          @click.stop="createEventHistory(1)"
        >
        <area
          class="checked"
          data-target="tips-03"
          coords="397,603,447,517,547,516,596,604,540,690,446,691,397,603"
          shape="poly"
          title="TIP03"
          @click.stop="createEventHistory(1)"
        >
        <area
          class="checked"
          data-target="tips-04"
          coords="550,504,599,419,699,419,748,505,700,593,599,592,550,504"
          shape="poly"
          title="TIP04"
          @click.stop="createEventHistory(2)"
        >
        <area
          class="checked"
          data-target="tips-05"
          coords="704,602,754,515,854,515,904,602,853,690,754,690,704,602"
          shape="poly"
          title="TIP05"
          @click.stop="createEventHistory(2)"
        >
        <area
          class="checked"
          data-target="tips-06"
          coords="704,784,753,697,853,697,903,784,853,871,753,871"
          shape="poly"
          title="TIP06"
          @click.stop="createEventHistory(2)"
        >
        <area
          class="checked"
          data-target="tips-07"
          coords="549,876,598,789,698,789,748,875,697,963,597,963"
          shape="poly"
          title="TIP07"
          @click.stop="createEventHistory(3)"
        >
        <area
          class="checked"
          data-target="tips-08"
          coords="392,964,442,878,541,878,591,965,542,1052,441,1052"
          shape="poly"
          title="TIP08"
          @click.stop="createEventHistory(3)"
        >
        <area
          class="checked"
          data-target="tips-09"
          coords="234,874,285,787,384,787,434,873,383,960,284,960"
          shape="poly"
          title="TIP09"
          @click.stop="createEventHistory(3)"
        >
        <area
          class="checked"
          data-target="tips-10"
          coords="100,706,104,700,226,699,230,705,230,725,225,729,240,745,251,760,257,787,254,818,243,849,216,886,206,894,124,895,111,885,78,829,73,780,86,748,105,727,100,723"
          shape="poly"
          title="TIP10"
          @click.stop="createEventHistory(3)"
        >
      </map>
    </div>
  </div>
</template>
<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { changeUserPoint } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'
import { numberFormatter } from '@/libs/utils/filters'

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      today: dayjs().format('YYYY-MM-DD'),
      completeEvent: false,
      historyCount: 0,
      isProcessing: false,
      // startDateStep1: '2024-02-05',
      // startDateStep2: '2024-02-14',
      // startDateStep3: '2024-02-21',
      // endDateStep1: '2024-02-13',
      // endDateStep2: '2024-02-20',
      // endDateStep3: '2024-02-29',
      startDateStep1: '2024-03-05',
      startDateStep2: '2024-03-14',
      startDateStep3: '2024-03-21',
      endDateStep1: '2024-03-13',
      endDateStep2: '2024-03-20',
      endDateStep3: '2024-03-31',
      historyCountStep1: 0,
      historyCountStep2: 0,
      historyCountStep3: 0,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
    }),
  },
  watch: {
    userName() {
      if (this.userName) this.fetchEventHistory()
      if (this.userName) this.fetchEventComplete()
    },
  },
  created() {
    if (this.userName) this.fetchEventHistory()
    if (this.userName) this.fetchEventComplete()
  },
  methods: {
    checkDateEnd(toDate) {
      return toDate < this.today
    },
    fetchEventHistory() {
      axios.get(`/fu/event/hiscount/${this.eventId}`)
        .then(rs => {
          this.historyCount = rs.data
        }).catch(() => {})

      if (this.today >= this.startDateStep1) {
        axios.get(`/fu/event/hiscount/${this.eventId}/${this.startDateStep1}/${this.endDateStep1}`)
          .then(rs => {
            this.historyCountStep1 = rs.data
          }).catch(() => {})
      }
      if (this.today >= this.startDateStep2) {
        axios.get(`/fu/event/hiscount/${this.eventId}/${this.startDateStep2}/${this.endDateStep2}`)
          .then(rs => {
            this.historyCountStep2 = rs.data
          }).catch(() => {})
      }
      if (this.today >= this.startDateStep3) {
        axios.get(`/fu/event/hiscount/${this.eventId}/${this.startDateStep3}/${this.endDateStep3}`)
          .then(rs => {
            this.historyCountStep3 = rs.data
          }).catch(() => {})
      }
    },
    fetchEventComplete() {
      axios.get(`/fu/event/complete/${this.eventId}/${this.contentId}`)
        .then(rs => {
          if (rs.data) {
            this.completeEvent = true
          } else this.completeEvent = false
        })
        .catch(() => {
        })
    },
    createEventHistory(step) {
      if (this.userName) {
        if (this.isProcessing) return

        if (this.completeEvent) {
          this.$parent.showAlertDialog('출석체크에 참여한 컨텐츠 입니다.')
          return
        }
        if (this.historyCount >= 10) {
          this.$parent.showAlertDialog('참여 가능한 출석체크를 모두 완료하셨습니다.')
          return
        }
        if (step === 1) {
          if (this.historyCountStep1 >= 3) {
            this.$parent.showAlertDialog('TIP1 ~ TIP3까지 모두 완료하였습니다.')
            return
          } if (this.today < this.startDateStep1) {
            this.$parent.showAlertDialog('3월 5일부터 참여 가능합니다.')
            return
          } if (this.today > this.endDateStep1) {
            this.$parent.showAlertDialog('TIP1 ~ TIP3 참여기간이 종료되었습니다.')
            return
          }
        }
        if (step === 2) {
          if (this.historyCountStep2 >= 3) {
            this.$parent.showAlertDialog('TIP4 ~ TIP6까지 모두 완료하였습니다.')
            return
          } if (this.today < this.startDateStep2) {
            this.$parent.showAlertDialog('3월 14일부터 참여 가능합니다.')
            return
          } if (this.today > this.endDateStep2) {
            this.$parent.showAlertDialog('TIP4 ~ TIP6 참여기간이 종료되었습니다.')
            return
          }
        }
        if (step === 3) {
          if (this.historyCountStep3 >= 4) {
            this.$parent.showAlertDialog('TIP7 ~ TIP10까지 모두 완료하였습니다.')
            return
          } if (this.today < this.startDateStep3) {
            this.$parent.showAlertDialog('3월 21일부터 참여 가능합니다.')
            return
          } if (this.today > this.endDateStep3) {
            this.$parent.showAlertDialog('참여기간이 종료되었습니다.')
            return
          }
        }

        // processing on
        this.isProcessing = true

        this.$parent.showConfirmDialog('출석 체크 하시겠습니까?', (result) => {
          if (result) {
            axios.post('/fu/event/history', {
              EventId: this.eventId,
              ContentId: this.contentId,
            })
              .then(rs => {
                setStatistics(121, this.eventId)

                if (rs.data && rs.data.pointBalance) changeUserPoint(rs.data.pointBalance)

                this.fetchEventHistory()

                this.$parent.showAlertDialog(`진료 꿀팁 모으기 이벤트 참여 완료하여 ${numberFormatter(rs.data.awardPoint)}포인트가 지급되었습니다.`, () => {
                  this.isProcessing = false
                  this.completeEvent = true
                })
              })
              .catch(() => {
                this.isProcessing = false

                this.$parent.showAlertDialog('진료 꿀팁 모으기 이벤트 처리 중 오류가 발생했습니다.')
              })
          } else {
            this.isProcessing = false
          }
        })
      } else {
        this.isProcessing = false

        this.redirectLogin()
      }
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-medical-trend-main' }, params: { id: this.contentId } }).catch(() => {})
    },
  }
}
</script>

<style scoped>
  body {
      margin: 0;
      padding: 0;
  }
  img {
      display: block;
      margin: 0 auto;
  }
  area:hover {
      cursor: pointer;
  }
  .event-board {
      width: 1000px;
      margin: 0 auto;
      position: relative;
  }
  .event-checked img {
      display: block;
      position: absolute;
  }
</style>
