var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "event-board", staticStyle: { "text-align": "center" } },
      [
        _c("div", { staticClass: "event-checked" }, [
          _vm.historyCountStep1 >= 1
            ? _c("img", {
                staticClass: "tips-01",
                staticStyle: { top: "519px", left: "89px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/completion-1.png"),
                  alt: "TIP01 적립완료",
                },
              })
            : _vm.checkDateEnd(_vm.endDateStep1)
            ? _c("img", {
                staticClass: "tips-01",
                staticStyle: { top: "519px", left: "89px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/fail-1.png"),
                  alt: "TIP01 적립실패",
                },
              })
            : _vm._e(),
          _vm.historyCountStep1 >= 2
            ? _c("img", {
                staticClass: "tips-02",
                staticStyle: { top: "420px", left: "243px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/completion-2.png"),
                  alt: "TIP02 적립완료",
                },
              })
            : _vm.checkDateEnd(_vm.endDateStep1)
            ? _c("img", {
                staticClass: "tips-02",
                staticStyle: { top: "420px", left: "243px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/fail-2.png"),
                  alt: "TIP02 적립실패",
                },
              })
            : _vm._e(),
          _vm.historyCountStep1 >= 3
            ? _c("img", {
                staticClass: "tips-03",
                staticStyle: { top: "518px", left: "397px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/completion-3.png"),
                  alt: "TIP03 적립완료",
                },
              })
            : _vm.checkDateEnd(_vm.endDateStep1)
            ? _c("img", {
                staticClass: "tips-03",
                staticStyle: { top: "518px", left: "397px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/fail-3.png"),
                  alt: "TIP03 적립실패",
                },
              })
            : _vm._e(),
          _vm.historyCountStep2 >= 1
            ? _c("img", {
                staticClass: "tips-04",
                staticStyle: { top: "419px", left: "550px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/completion-4.png"),
                  alt: "TIP04 적립완료",
                },
              })
            : _vm.checkDateEnd(_vm.endDateStep2)
            ? _c("img", {
                staticClass: "tips-04",
                staticStyle: { top: "419px", left: "550px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/fail-4.png"),
                  alt: "TIP04 적립실패",
                },
              })
            : _vm._e(),
          _vm.historyCountStep2 >= 2
            ? _c("img", {
                staticClass: "tips-05",
                staticStyle: { top: "516px", right: "96px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/completion-5.png"),
                  alt: "TIP05 적립완료",
                },
              })
            : _vm.checkDateEnd(_vm.endDateStep2)
            ? _c("img", {
                staticClass: "tips-05",
                staticStyle: { top: "516px", right: "96px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/fail-5.png"),
                  alt: "TIP05 적립실패",
                },
              })
            : _vm._e(),
          _vm.historyCountStep2 >= 3
            ? _c("img", {
                staticClass: "tips-06",
                staticStyle: { top: "698px", right: "97px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/completion-6.png"),
                  alt: "TIP06 적립완료",
                },
              })
            : _vm.checkDateEnd(_vm.endDateStep2)
            ? _c("img", {
                staticClass: "tips-06",
                staticStyle: { top: "698px", right: "97px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/fail-6.png"),
                  alt: "TIP06 적립실패",
                },
              })
            : _vm._e(),
          _vm.historyCountStep3 >= 1
            ? _c("img", {
                staticClass: "tips-07",
                staticStyle: { top: "790px", left: "549px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/completion-7.png"),
                  alt: "TIP07 적립완료",
                },
              })
            : _vm.checkDateEnd(_vm.endDateStep3)
            ? _c("img", {
                staticClass: "tips-07",
                staticStyle: { top: "790px", left: "549px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/fail-7.png"),
                  alt: "TIP07 적립실패",
                },
              })
            : _vm._e(),
          _vm.historyCountStep3 >= 2
            ? _c("img", {
                staticClass: "tips-08",
                staticStyle: { top: "879px", left: "392px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/completion-8.png"),
                  alt: "TIP08 적립완료",
                },
              })
            : _vm.checkDateEnd(_vm.endDateStep3)
            ? _c("img", {
                staticClass: "tips-08",
                staticStyle: { top: "879px", left: "392px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/fail-8.png"),
                  alt: "TIP08 적립실패",
                },
              })
            : _vm._e(),
          _vm.historyCountStep3 >= 3
            ? _c("img", {
                staticClass: "tips-09",
                staticStyle: { top: "788px", left: "235px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/completion-9.png"),
                  alt: "TIP09 적립완료",
                },
              })
            : _vm.checkDateEnd(_vm.endDateStep3)
            ? _c("img", {
                staticClass: "tips-09",
                staticStyle: { top: "788px", left: "235px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/fail-9.png"),
                  alt: "TIP09 적립실패",
                },
              })
            : _vm._e(),
          _vm.historyCountStep3 >= 4
            ? _c("img", {
                staticClass: "tips-10",
                staticStyle: { top: "700px", left: "73px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/completion-10.png"),
                  alt: "TIP10 적립완료",
                },
              })
            : _vm.checkDateEnd(_vm.endDateStep3)
            ? _c("img", {
                staticClass: "tips-10",
                staticStyle: { top: "698px", left: "73px" },
                attrs: {
                  src: require("@/assets/images/events/event240205/fail-10.png"),
                  alt: "TIP10 적립실패",
                },
              })
            : _vm._e(),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/events/event240205/bg_event_stamp.jpg"),
            alt: "당뇨병 콩팥병 진료 꿀팁모으기",
            usemap: "#tips-map",
          },
        }),
        _c("map", { attrs: { name: "tips-map" } }, [
          _c("area", {
            staticClass: "checked",
            attrs: {
              "data-target": "tips-01",
              coords: "88,605,139,518,239,518,288,606,238,693,138,692,88,605",
              shape: "poly",
              title: "TIP01",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createEventHistory(1)
              },
            },
          }),
          _c("area", {
            staticClass: "checked",
            attrs: {
              "data-target": "tips-02",
              coords: "90,519,292,419,392,419,442,506,392,594,292,594,242,506",
              shape: "poly",
              title: "TIP02",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createEventHistory(1)
              },
            },
          }),
          _c("area", {
            staticClass: "checked",
            attrs: {
              "data-target": "tips-03",
              coords: "397,603,447,517,547,516,596,604,540,690,446,691,397,603",
              shape: "poly",
              title: "TIP03",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createEventHistory(1)
              },
            },
          }),
          _c("area", {
            staticClass: "checked",
            attrs: {
              "data-target": "tips-04",
              coords: "550,504,599,419,699,419,748,505,700,593,599,592,550,504",
              shape: "poly",
              title: "TIP04",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createEventHistory(2)
              },
            },
          }),
          _c("area", {
            staticClass: "checked",
            attrs: {
              "data-target": "tips-05",
              coords: "704,602,754,515,854,515,904,602,853,690,754,690,704,602",
              shape: "poly",
              title: "TIP05",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createEventHistory(2)
              },
            },
          }),
          _c("area", {
            staticClass: "checked",
            attrs: {
              "data-target": "tips-06",
              coords: "704,784,753,697,853,697,903,784,853,871,753,871",
              shape: "poly",
              title: "TIP06",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createEventHistory(2)
              },
            },
          }),
          _c("area", {
            staticClass: "checked",
            attrs: {
              "data-target": "tips-07",
              coords: "549,876,598,789,698,789,748,875,697,963,597,963",
              shape: "poly",
              title: "TIP07",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createEventHistory(3)
              },
            },
          }),
          _c("area", {
            staticClass: "checked",
            attrs: {
              "data-target": "tips-08",
              coords: "392,964,442,878,541,878,591,965,542,1052,441,1052",
              shape: "poly",
              title: "TIP08",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createEventHistory(3)
              },
            },
          }),
          _c("area", {
            staticClass: "checked",
            attrs: {
              "data-target": "tips-09",
              coords: "234,874,285,787,384,787,434,873,383,960,284,960",
              shape: "poly",
              title: "TIP09",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createEventHistory(3)
              },
            },
          }),
          _c("area", {
            staticClass: "checked",
            attrs: {
              "data-target": "tips-10",
              coords:
                "100,706,104,700,226,699,230,705,230,725,225,729,240,745,251,760,257,787,254,818,243,849,216,886,206,894,124,895,111,885,78,829,73,780,86,748,105,727,100,723",
              shape: "poly",
              title: "TIP10",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createEventHistory(3)
              },
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }